<script setup>

import TransportLayout from "../../../layouts/TransportLayout.vue";
import { onBeforeMount } from "vue";
import { useRoute } from "vue-router/auto";
import useTransportOrder  from "../../../hooks/useTransportOrders.js";
import { setPageTitle } from "@/utils/pageTitle";

const route = useRoute();

const {
    fetchTransportOrder,
    order,
    fetchTransportOrderHistory,
    history,
} = useTransportOrder();
onBeforeMount(async () => {
    await fetchTransportOrder(route.params.id);
    await fetchTransportOrderHistory(route.params.id);
    setPageTitle(`Transportation Orders // ${order.value.id}`);
});
</script>

<template>
    <TransportLayout v-if="order" :transportOrder="order" >
        <div v-if="!history || history?.length == 0" class="pa-4 text-center text-disabled">No History Available</div>
        <v-row v-else v-for="hist in history" class="ma-4">
            <v-col cols="12" md="4">
                <div class="text-h5">{{hist.user.name}}</div>
                <p>{{hist.created_at}}</p>
            </v-col>
            <v-col cols="12" md="8">
                <p>{{hist.body}}</p>
            </v-col>
        </v-row>
    </TransportLayout>
</template>


<style scoped>

.text-h5 {
    /*needs to be inside scope different font used in other components */
    font-family: 'Source Sans 3', 'sans-serif' !important;
    font-size: 1rem !important;
    font-weight: bold;
}
</style>